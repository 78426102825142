$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
.second-page {
  .fare-section {
    margin-top: 60px;
    padding-top: 40px;
    padding-bottom: 60px;

    .recaptcha-box {
      & > div > div > div {
        margin: auto;
      }
    }

    .price-info-bar {

    }
  }
}
