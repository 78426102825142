$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

.nav-bar-main {
  background-color: #001841;
}

.page-footer {
  background-color: #001841;
  color: white;

  .nav-link {
    color: white;
  }
}

.navbar .nav-link {
  padding-right: 30px !important;
  color: white !important;
  font-weight: 500 !important;

  &:hover {
    color: #f57d01;
    font-weight: 500;
  }
}

.nav-item-custom {
  padding-right: 50px;
}

.signup-btn {
  background-image: linear-gradient(to bottom, #fe9800, #f37800);
  color: white;
  font-weight: 500;
}

.signup-btn:hover {
  background-image: linear-gradient(to bottom, #ffc26a, #f38f2d);
  color: white;
  font-weight: 500;
}

.navbar-toggler {
  line-height: 0;
  font-size: inherit;
  color: white;
}

body {
  background-image: url("../assets/images/home-bg-img-01.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.logo {
  width: 100%;
  max-width: 300px;
}

button.nav-link {
  padding: 20px;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: none;
  color: #1e1e1e;
  flex-grow: 1;
}

button.nav-link.active {
  background-color: transparent;
  border-radius: 5px;
  border: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #5392f9;
}

.form-btn-svg {
  width: 50px;
  height: 50px;
}

#nav-home-tab {
  background-color: transparent;
}

.form-btn {
  color: #1e1e1e;
}

.home-form {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  border: 1px solid white;
  border-radius: 30px;

  .home-form-outer {
    position: relative;

    .home-form-header-bar {
      width: 100%;
      position: absolute;
      top: -90px;

      .nav {
        max-width: 600px;
        background-color: rgb(189, 189, 189);
        border-radius: 5px;
        margin: 0 auto;

        .nav-mode-tab {
          padding: 15px;
          background-color: rgba(0, 0, 0, 0);

          &:hover {
            background-color: #ffffff;
            color: black;
          }

          &.active {
            background-color: #ffffff;
            color: black;
            font-weight: bold;
            border-bottom: 3px solid #ff7700;
          }

          .nav-mode-img {
            margin-bottom: 10px;
          }

          .nav-mode-label {
          }
        }
      }
    }

    .home-form-inner {
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 60px;
    }

    .home-form-footer-bar {
      width: 100%;
      position: absolute;
      bottom: -50px;

      & > div {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }
}

.home-content {
  padding-top: 80px;
  text-align: center;
  color: white;

  .service-item {
    text-align: center;
    padding-top: 20px;

    img {
      max-width: 80px;
      padding-bottom: 20px;
    }
  }

  .package-item-col {
    padding-bottom: 50px;

    .package-item {
      text-align: center;
      background-color: rgba(255, 255, 255, 0.4);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      border: 1px solid #f18902;
      border-radius: 30px;
      color: black;
      height: 100%;

      img {
        width: 100%;
        padding-bottom: 20px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }

      position: relative;

      .content-box {
        padding: 0 20px;
        margin-bottom: 20px;

        .item-title {
          font-weight: bold;
          color: #021941;
        }
      }

      .price-box-wrapper {
        text-align: center;

        .price-box {
          font-size: 24px;
          background-image: linear-gradient(to bottom, #fe9800, #f37800);
          position: absolute;
          bottom: -18px;
          left: 20%;
          right: 20%;
          border-radius: 25px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}

.form-select {
  border-radius: 10px;
}

.subTitles {
  color: #1e1e1e;
  font-weight: bold;
}


.travel-fare-group {
  background-color: #4fffb0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

h4 {
  margin: 0;
  line-height: 1.5;
}

.price {
  font-weight: bolder;
  font-style: italic;
}

.home-form-footer-bar-inner {
  display: flex;
  flex-wrap: nowrap;

  .travel-fare-group {
    flex-grow: 1;
    font-size: 26px;
    padding: 1.5rem 0.75rem;
  }

  .booknow-btn {
    padding: 1.5rem 0.75rem;
    flex-grow: 1;
    background-color: #e2e1e5;
    color: #1e1e1e;
    font-weight: bold;
    border-radius: 0 10px 10px 0;
    border: none;
    font-size: 26px;
    background-image: linear-gradient(to bottom, #fe9800, #f37800);

    &:hover {
      background-image: linear-gradient(to bottom, #ffc26a, #f38f2d);
    }
  }

}

@media (max-width: $breakpoint-md) {
  .home-form {
    .home-form-outer {
      .home-form-header-bar {
        .nav {
        }
      }

      .home-form-inner {
        .nav-mode-tab {
          .nav-mode-img {
          }

          .nav-mode-label {
          }
        }
      }

      .home-form-footer-bar {
        bottom: -90px;

        & > div {
        }
      }
    }
  }
}

