.contact-main {
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid white;
  border-radius: 30px;
  color: black;
  width: 80%;
}

.contact-main-title {
  color: white;
  font-weight: bold;
  margin: 0 auto;
  width: 50%;
  background-image: linear-gradient(to bottom, #03296a, #011b46);
  border-radius: 10px;
  border: 1px solid #5291f7;
  transform: translateY(-50%);
}

.contact-title {
  font-size: xx-large;
  font-weight: bolder;
}

.contact-sub-title {
  font-size: large;
  padding-bottom: 40px;
}

.contact-details {
  font-weight: normal;
}

.contact-img .img-fluid {
  max-width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.contact-img {
  margin: auto;
}

.contact-details-main a {
  text-decoration: none;
  color: black;
}
