.App {

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*features-section*/

.features-main-icon, .features-book-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.feature-card-title {
  background: #001841;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}

.feature-card-content-main {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-radius: 0 0 10px 10px;
}

.feature-card-content {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.features-book-btn {
  border-radius: 50px;
  background-image: linear-gradient(to bottom, #fe9800, #f37800);
  border: none;
}

.page-not-found .img-fluid {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.page-not-found {
  padding-top: 20%;
}

.go-home-btn {
  border-radius: 50px;
  background-image: linear-gradient(to bottom, #fe9800, #f37800);
  border: none;
  font-weight: bold;
}

.PhoneInputInput {
  border: none;
}
