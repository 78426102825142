.feature-card-main {
  height: 100%;

  .feature-card-content-main {
    height: calc(100% - 125px);
  }
}

.footer-license-img {
  width: 80%;
  height: auto;
}
