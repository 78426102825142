.nav-bar-main {
    background-color: #001841;
}

.signup-btn {
    background-image: linear-gradient(to bottom, #fe9800, #f37800);
    color: white;
    font-weight: 500;
}

.signup-btn:hover {
    background-image: linear-gradient(to bottom, #ffc26a, #f38f2d);
    color: white;
    font-weight: 500;
    border: none;
}

.navbar-toggler {
    line-height: 0;
    font-size: inherit;
    color: white;
}


.note {
    background-color: #870000;
    line-height: 2;
}

.form-select, .form-control {
    border-radius: 10px;
}

.booking-form {
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid white;
    border-radius: 30px;
}

.title-bar-bg {
    margin: 0 auto;
    width: 60%;
    background-image: linear-gradient(to bottom, #03296a, #011b46);
    border-radius: 10px;
    border: 1px solid #5291f7;
    transform: translateY(+20px);
}

.main-titles {
    margin: 0 auto;
}

.form-sub-title {
    color: #1e1e1e;
    font-weight: bold;
}

.adults-children {
    font-weight: normal;
}

.fare-section {
    background-color: #000d23;
}

.fare-main {
    width: 50%;
    margin: 0 auto;
}

.fare {
    font-weight: bold;
    font-style: italic;
}

.your-travel-fare {
    font-size: xxx-large;
    color: #00ff85;
}

.nighttime-charge {
    color: #df9396;
}

.btn-reservation-button {
    background-image: linear-gradient(to bottom, #fe9800, #f37800);
    color: white;
    font-weight: 500;
    height: 30%;
    padding: 20px;
}

.btn-reservation-button:hover {
    color: white;
    border: none;
    background-image: linear-gradient(to bottom, #ffc26a, #f38f2d);
    box-shadow: #1e2329 0px 2px 8px 0px;
}
