.rates-page {
  .row-title {
    text-align: center;
    height: 40px;
    color: #fff;
    font-size: 18px;
    margin: 0 auto;
    width: 60%;
    background-image: linear-gradient(to bottom, #03296a, #011b46);
    border-radius: 10px;
    /*border: 1px solid #5291f7;*/
  }

  .style02 {
    color: black;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.61);
  }

  .style03 {
    color: black;
    font-size: 15px;
    cursor: pointer;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.61);
    height: 54px;

  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }

  .align-center {
    text-align: center;
  }

  table tr {
    height: 35px;
  }

  th, td {
    padding: 10px;
    /*border: 2px solid #fff;*/
  }

  .table-container-outer {
    position: relative;
  }

  .table-container {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 1em;
    margin-bottom: 40px;
  }

  .table-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
  }

  .table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, .3);
  }


  .table-coner-round-tl {
    border-top-left-radius: 10px;
  }

  .table-coner-round-tr {
    border-top-right-radius: 10px;
  }

  .table-coner-round-bl {
    border-bottom-left-radius: 10px;
  }

  .table-coner-round-br {
    border-bottom-right-radius: 10px;
  }

  h2 {
    color: white;
  }
}
